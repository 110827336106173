import { SitePropertiesService } from '@wix/ambassador-site-properties-service/http';
import { AmbassadorBaseUrls } from '../../constants/ambassador';

export async function getBusinessManagerSiteLanguage(
  authorization: string,
): Promise<string | undefined> {
  const sitePropertiesService = SitePropertiesService(
    AmbassadorBaseUrls.SitePropertiesService,
  ).SitePropertiesV4()({
    authorization,
  });

  const response = await sitePropertiesService.read({
    fields: {
      paths: ['language'],
    },
  });

  return response.properties?.language;
}
