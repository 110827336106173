import { injectable } from 'inversify';
import { IStoreService } from './StoreService.types';

@injectable()
export class StoreService implements IStoreService {
  private storeJson: string | undefined = undefined;

  public getStoreJson() {
    return this.storeJson;
  }

  public setStoreJson(store: string) {
    this.storeJson = store;
  }
}
