import { PredictorPrimeService } from '@wix/ambassador-predictor-prime-service/http';
import { AmbassadorBaseUrls } from '../../constants/ambassador';

export const hasImageTextByDataScience = async (
  base64Image: string,
): Promise<boolean> => {
  const service = PredictorPrimeService(
    AmbassadorBaseUrls.PredictorPrimeService,
  ).Predictor()();

  const { output } = await service.invokeByModel({
    features: {
      values: {
        encoded_image: {
          strVal: base64Image,
        },
      },
    },
    modelId: 'ds-text-detection-0-1',
    triggeredBy: 'testing',
  });

  if (output?.values?.result.strVal) {
    return JSON.parse(output.values.result.strVal).has_text;
  }

  return false;
};
