import { WixBiProfileWebapp } from '@wix/ambassador-wix-bi-profile-webapp/http';
import { AmbassadorBaseUrls } from '../../constants/ambassador';

export const getDataScienceSiteLanguage = async (
  userId: string,
  metaSiteId: string,
): Promise<string | undefined> => {
  const profileDataService = WixBiProfileWebapp(
    AmbassadorBaseUrls.ProfileDataService,
  ).ProfileDataService()();

  const { fields } = await profileDataService.get({
    fields: ['site_language'],
    metasiteGuid: metaSiteId,
    userGuid: userId,
  });

  return fields?.site_language?.aSingleValue?.aString;
};
