import { Container } from 'inversify';
import { StoreService } from '../services/StoreService/StoreService';
import { IStoreService } from '../services/StoreService/StoreService.types';
import { editorServices } from './services';

export function inversifyEditorConfig(container: Container) {
  container
    .bind<IStoreService>(editorServices.store)
    .to(StoreService)
    .inSingletonScope();
}
